define("ember-resource-metadata/adapter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    _resourceMetadata: Ember.inject.service('resource-metadata'),
    _correlateMetadata: function _correlateMetadata(record, fn) {
      var _this = this;
      return fn().then(function (response) {
        var data = response.data,
          included = response.included;
        _this._correlatePayload(data, record);
        if (included) {
          _this._correlatePayload(included, record);
        }
        return response;
      });
    },
    _correlateResource: function _correlateResource(record, hash) {
      if (hash.meta) {
        var _service = this.get('_resourceMetadata');
        if (record) {
          _service.write(record, hash.meta);
        } else {
          _service.write({
            id: hash.id,
            type: hash.type
          }, hash.meta);
        }
      }
    },
    _correlatePayload: function _correlatePayload(data, record) {
      var _this2 = this;
      if (Array.isArray(data)) {
        data.forEach(function (hash) {
          return _this2._correlateResource(null, hash);
        });
      } else {
        this._correlateResource(record, data);
      }
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this3 = this;
      return this._correlateMetadata(snapshot.record, function () {
        return _this3._super(store, type, snapshot);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this4 = this;
      return this._correlateMetadata(snapshot.record, function () {
        return _this4._super(store, type, snapshot);
      });
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var _this5 = this;
      return this._correlateMetadata(snapshot.record, function () {
        return _this5._super(store, type, id, snapshot);
      });
    },
    queryRecord: function queryRecord(store, type, query) {
      var _this6 = this;
      if (query.disableResourceMetadata) {
        query = Object.assign({}, query);
        delete query.disableResourceMetadata;
        return this._super(store, type, query);
      }
      return this._correlateMetadata(null, function () {
        return _this6._super(store, type, query);
      });
    },
    query: function query(store, type, _query) {
      var _this7 = this;
      return this._correlateMetadata(null, function () {
        return _this7._super(store, type, _query);
      });
    }
  });
});