define("ember-scrollable/templates/components/ember-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t0m63+5R",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"#\",[33,2]],null]],[[\"on-resize\"],[[28,[37,3],[[30,0],\"recalculate\"],null]]]]],[1,\"\\n\"],[41,[33,5],[[[1,\"  \"],[1,[28,[35,6],null,[[\"handleOffset\",\"handleSize\",\"horizontal\",\"showHandle\",\"isDragging\",\"onJumpTo\",\"onDrag\",\"onDragStart\",\"onDragEnd\"],[[33,7],[33,8],true,[33,9],[33,10],[28,[37,3],[[30,0],\"horizontalJumpTo\"],null],[28,[37,3],[[30,0],\"horizontalDrag\"],null],[28,[37,3],[[30,0],\"horizontalDragBoundary\",true],null],[28,[37,3],[[30,0],\"horizontalDragBoundary\",false],null]]]]],[1,\"\\n\"]],[]],null],[41,[33,11],[[[1,\"  \"],[1,[28,[35,6],null,[[\"handleOffset\",\"handleSize\",\"horizontal\",\"showHandle\",\"isDragging\",\"onJumpTo\",\"onDrag\",\"onDragStart\",\"onDragEnd\"],[[33,12],[33,13],false,[33,9],[33,14],[28,[37,3],[[30,0],\"verticalJumpTo\"],null],[28,[37,3],[[30,0],\"verticalDrag\"],null],[28,[37,3],[[30,0],\"verticalBoundaryEvent\",true],null],[28,[37,3],[[30,0],\"verticalBoundaryEvent\",false],null]]]]],[1,\"\\n\"]],[]],null],[6,[39,15],null,[[\"height\",\"width\",\"scrollToX\",\"scrollToY\",\"onScroll\"],[[33,16],[33,17],[33,18],[33,19],[28,[37,3],[[30,0],\"scrolled\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"tse-content scrollable-content\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,21],null,[[\"recalculate\",\"update\",\"scrollTop\"],[[28,[37,3],[[30,0],\"recalculate\"],null],[28,[37,3],[[30,0],\"update\"],null],[28,[37,3],[[30,0],\"scrollTop\"],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"resize-detector\",\"concat\",\"elementId\",\"action\",\"if\",\"horizontal\",\"ember-scrollbar\",\"horizontalHandleOffset\",\"horizontalHandleSize\",\"showHandle\",\"isHorizontalDragging\",\"vertical\",\"verticalHandleOffset\",\"verticalHandleSize\",\"isVerticalDragging\",\"scroll-content-element\",\"scrollContentHeight\",\"scrollContentWidth\",\"scrollToX\",\"scrollToY\",\"yield\",\"hash\"]]",
    "moduleName": "ember-scrollable/templates/components/ember-scrollable.hbs",
    "isStrictMode": false
  });
});