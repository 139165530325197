define("@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vdj4Eknw",
    "block": "[[[10,0],[14,\"role\",\"mount-point\"],[12],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"stripeElement\"]],[30,0,[\"stripeError\"]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-number.hbs",
    "isStrictMode": false
  });
});