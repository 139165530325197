define("ember-concurrency/-private/external/task-decorators", ["exports", "ember-concurrency/-private/external/task-factory"], function (_exports, _taskFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTaskDecorator = createTaskDecorator;
  _exports.createTaskGroupDecorator = createTaskGroupDecorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.lastValue = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function taskFromPropertyDescriptor(target, key, descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    var initializer = descriptor.initializer,
      get = descriptor.get,
      value = descriptor.value;
    var taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = "".concat(key, " (task)");
    var tasks = new WeakMap();
    var options = params[0] || {};
    var factory = new factoryClass(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get: function get() {
        var task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(_target, key, _descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    var taskGroups = new WeakMap();
    var options = params[0] || {};
    var factory = new factoryClass(key, null, options);
    return {
      get: function get() {
        var task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc = _slicedToArray(possibleDesc, 3),
      target = _possibleDesc[0],
      key = _possibleDesc[1],
      desc = _possibleDesc[2];
    return possibleDesc.length === 3 && _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }

  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
        params[_key2] = arguments[_key2];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn.apply(void 0, params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
            desc[_key3] = arguments[_key3];
          }
          return descriptorFn.apply(void 0, desc.concat([params]));
        };
      }
    };
  }
  function createDecorator(fn) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var factoryClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _taskFactory.TaskFactory;
    return decoratorWithParams(function (target, key, descriptor) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
        _ref2 = _slicedToArray(_ref, 1),
        userOptions = _ref2[0];
      var mergedOptions = Object.assign({}, _objectSpread(_objectSpread({}, baseOptions), userOptions));
      return fn(target, key, descriptor, [mergedOptions], factoryClass);
    });
  }
  function createTaskDecorator() {
    var baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskFromPropertyDescriptor, baseOptions, factoryClass);
  }
  function createTaskGroupDecorator() {
    var baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskGroupPropertyDescriptor, baseOptions, factoryClass);
  }
  var lastValue = _exports.lastValue = decoratorWithParams(function (_target, _key, descriptor) {
    var _ref3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
      _ref4 = _slicedToArray(_ref3, 1),
      taskName = _ref4[0];
    var initializer = descriptor.initializer;
    delete descriptor.initializer;
    return {
      get: function get() {
        var lastInstance = this[taskName].lastSuccessful;
        if (lastInstance) {
          return lastInstance.value;
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      }
    };
  });
});