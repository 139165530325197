define("ember-element-resize-detector/services/resize-detector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var error = Ember.Logger.error;
  var _default = _exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.detector = elementResizeDetectorMaker({
        strategy: "scroll"
      });
    },
    setup: function setup(selector, callback) {
      var element = document.querySelector(selector);
      if (!element) {
        error("service:resize-detector - could not find an element matching ".concat(selector));
        return;
      }
      this.detector.listenTo(element, callback);
    },
    teardown: function teardown(selector, callback) {
      var element = document.querySelector(selector);
      if (element) {
        this.detector.removeListener(element, callback);
      }
    }
  });
});