define("@adopted-ember-addons/ember-stripe-elements/components/stripe-card", ["exports", "@adopted-ember-addons/ember-stripe-elements/components/stripe-element", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card"], function (_exports, _stripeElement, _stripeCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _stripeElement.default.extend({
    layout: _stripeCard.default,
    classNames: ['ember-stripe-card'],
    type: 'card'
  });
});