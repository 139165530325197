define("ember-select/components/select-dropdown", ["exports", "ember-select/templates/components/select-dropdown", "ember-select/utils/tree", "ember-select/utils/view"], function (_exports, _selectDropdown, _tree, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend({
    layout: _selectDropdown.default,
    list: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.parent.on('keyPress', this, this.keys);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var options = this.getProperties('valueKey', 'labelKey');
      var model = this.get('model');
      var list = (0, _tree.buildTree)(model, options);
      this.set('list', list);
      this.filterModel();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.parent.off('keyPress', this, this.keys);
    },
    options: Ember.computed('token', 'model.[]', 'values.[]', function () {
      if (this.get('shouldFilter')) {
        this.filterModel();
      }
      return this.get('list');
    }),
    actions: {
      hover: function hover(node) {
        var selected = this.get('selected');
        if (selected) {
          selected.set('isSelected', false);
        }
        this.set('selected', node);
        node.set('isSelected', true);
      },
      select: function select(node) {
        this.select(node.content || node.id, true);
      }
    },
    /* Filter out existing selections. Mark everything
     visible if no search, otherwise update visiblity. */
    filterModel: function filterModel() {
      var list = this.get('list');
      var token = this.get('token');
      var values = this.get('values');
      list.forEach(function (el) {
        return el.set('isVisible', false);
      });
      if (Ember.isPresent(values)) {
        list = list.filter(function (el) {
          return values.indexOf(el.content) === -1;
        });
      }
      if (Ember.isEmpty(token)) {
        list.forEach(function (el) {
          return el.set('isVisible', true);
        });
      } else {
        token = typeof token === 'string' ? token.toLowerCase() : token;
        this.setVisibility(list, token);
      }

      // Mark first visible element as selected
      if (!this.get('freeText') && Ember.isPresent(token) && list.some(function (x) {
        return Ember.get(x, 'isVisible');
      })) {
        var _list$filter = list.filter(function (x) {
            return Ember.get(x, 'isVisible');
          }),
          _list$filter2 = _slicedToArray(_list$filter, 1),
          firstVisible = _list$filter2[0];
        firstVisible.set('isSelected', true);
        this.set('selected', firstVisible);
      }
    },
    keys: function keys(event) {
      var selected = this.get('selected');
      switch (event.keyCode) {
        case 9: // TAB
        case 13:
          // Enter
          this.tabEnterKeys(selected);
          break;
        case 38: // Up
        case 40:
          // Down
          this.upDownKeys(selected, event);
          break;
      }
    },
    // Prevent event bubbling up
    mouseDown: function mouseDown(event) {
      event.preventDefault();
    },
    // Down: 40, Up: 38
    move: function move(list, selected, direction) {
      if (Ember.isPresent(selected)) {
        selected.set('isSelected', false);
      }
      if (Ember.isEmpty(list)) {
        return;
      }
      var index = list.indexOf(selected);
      var node;
      if (direction === 38) {
        if (index !== -1) {
          node = list[index - 1];
        }
        if (Ember.isNone(node)) {
          node = list[list.length - 1];
        }
      } else if (direction === 40) {
        if (index !== -1) {
          node = list[index + 1];
        }
        if (Ember.isNone(node)) {
          node = list[0];
        }
      }
      this.set('selected', node);
      node.set('isSelected', true);
      Ember.run.next(this, _view.bringInView, '.es-options', '.es-highlight');
    },
    setVisibility: function setVisibility(list, token) {
      list.filter(function (el) {
        return Ember.get(el, 'name').toString().toLowerCase().indexOf(token) > -1;
      }).forEach(function (el) {
        return el.set('isVisible', true);
      });
    },
    tabEnterKeys: function tabEnterKeys(selected) {
      if (selected && this.get('list').includes(selected)) {
        this.send('select', selected);
      } else if (this.get('freeText')) {
        this.select(this.get('token'));
      }
    },
    upDownKeys: function upDownKeys(selected, event) {
      var list = this.get('list').filterBy('isVisible');
      this.move(list, selected, event.keyCode);
    }
  });
});