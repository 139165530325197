define("ember-uploader/uploaders/uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    /**
     * Target url to upload to
     *
     * @property url
     */
    url: null,
    /**
     * ajax request method, by default it will be POST
     *
     * @property method
     */
    method: 'POST',
    /**
     * Used to define a namespace for the file param and any extra data params
     * that may be sent
     *
     * @property paramNamespace
     */
    paramNamespace: null,
    /**
     * The parameter name for the file(s) to be uploaded
     *
     * @property paramName
     */
    paramName: 'file',
    /**
     * Boolean property changed to true upon upload start and false upon upload
     * end
     *
     * @property isUploading
     */
    isUploading: false,
    /**
     * Start upload of file(s) and any extra data
     *
     * @param  {object|array} files  One file object or one array of files object
     * @param  {object} extra Extra data to be sent with the upload
     * @return {object} Returns a Ember.RSVP.Promise wrapping the ajax request
     * object
     */
    upload: function upload(files) {
      var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var data = this.createFormData(files, extra);
      var url = Ember.get(this, 'url');
      var method = Ember.get(this, 'method');
      Ember.set(this, 'isUploading', true);
      return this.ajax(url, data, method);
    },
    /**
     * Creates the FormData object with the file(s) and any extra data
     *
     * @param {object|array} files One file object or an array of file objects
     * @param {object} extra Extra data to be sent with the upload
     * @return {object} Returns a FormData object with the supplied file(s) and
     * extra data
     */
    createFormData: function createFormData(files) {
      var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var formData = new FormData();
      for (var prop in extra) {
        if (extra.hasOwnProperty(prop)) {
          formData.append(this.toNamespacedParam(prop), extra[prop]);
        }
      }

      // if is a array of files ...
      if (files.constructor === FileList || files.constructor === Array) {
        var paramKey = "".concat(this.toNamespacedParam(this.paramName), "[]");
        for (var i = 0; i < files.length; i++) {
          // FormData expects the key for arrays to be postfixed with empty
          // brackets This same key is used each time a new item is added.
          formData.append(paramKey, files[i]);
        }
      } else {
        // if has only one file object ...
        formData.append(this.toNamespacedParam(this.paramName), files);
      }
      return formData;
    },
    /**
     * Returns the param name namespaced if a namespace exists
     *
     * @param {string} name The param name to namespace
     * @return {string} Returns the namespaced param
     */
    toNamespacedParam: function toNamespacedParam(name) {
      return this.paramNamespace ? "".concat(this.paramNamespace, "[").concat(name, "]") : name;
    },
    /**
     * Triggers didUpload event with given params and sets isUploading to false
     *
     * @param {object} data Object of data supplied to the didUpload event
     * @return {object} Returns the given data
     */
    didUpload: function didUpload(data) {
      Ember.set(this, 'isUploading', false);
      this.trigger('didUpload', data);
      return data;
    },
    /**
     * Triggers didError event with given params and sets isUploading to false
     *
     * @param {object} jqXHR jQuery XMLHttpRequest object
     * @param {string} textStatus The status code of the error
     * @param {object} errorThrown The error caused
     * @return {object} Returns the jQuery XMLHttpRequest
     */
    didError: function didError(jqXHR, textStatus, errorThrown) {
      Ember.set(this, 'isUploading', false);

      // Borrowed from Ember Data
      var isObject = jqXHR !== null && _typeof(jqXHR) === 'object';
      if (isObject) {
        jqXHR.then = null;
        if (!jqXHR.errorThrown) {
          if (typeof errorThrown === 'string') {
            jqXHR.errorThrown = new Error(errorThrown);
          } else {
            jqXHR.errorThrown = errorThrown;
          }
        }
      }
      this.trigger('didError', jqXHR, textStatus, errorThrown);
      return jqXHR;
    },
    /**
     * Triggers progress event supplying event with current percent
     *
     * @param {object} event Event from xhr onprogress
     */
    didProgress: function didProgress(event) {
      event.percent = event.loaded / event.total * 100;
      this.trigger('progress', event);
    },
    /**
     * Triggers isAborting event and sets isUploading to false
     */
    abort: function abort() {
      Ember.set(this, 'isUploading', false);
      this.trigger('isAborting');
    },
    /**
     * Starts a request to the given url sending the supplied data using the
     * supplied request method
     *
     * @param {string} url The target url for the request
     * @param {object} data The data to send with the request
     * @param {string} method The request method
     * @return {object} Returns a Ember.RSVP.Promise wrapping the ajax request
     * object
     */
    ajax: function ajax(url) {
      var _this = this;
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.method;
      var ajaxSettings = Ember.assign({}, {
        contentType: false,
        processData: false,
        xhr: function xhr() {
          var xhr = Ember.$.ajaxSettings.xhr();
          xhr.upload.onprogress = function (event) {
            _this.didProgress(event);
          };
          _this.one('isAborting', function () {
            return xhr.abort();
          });
          return xhr;
        },
        url: url,
        data: data,
        method: method
      }, Ember.get(this, 'ajaxSettings'));
      return this.ajaxPromise(ajaxSettings);
    },
    /**
     * Starts a request using the supplied settings returning a
     * Ember.RSVP.Promise wrapping the ajax request
     *
     * @param {object} settings The jQuery.ajax compatible settings object
     * @return {object} Returns a Ember.RSVP.Promise wrapping the ajax request
     */
    ajaxPromise: function ajaxPromise(settings) {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        settings.success = function (data) {
          Ember.run(null, resolve, _this2.didUpload(data));
        };
        settings.error = function (jqXHR, responseText, errorThrown) {
          Ember.run(null, reject, _this2.didError(jqXHR, responseText, errorThrown));
        };
        Ember.$.ajax(settings);
      });
    }
  });
});