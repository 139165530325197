define("@adopted-ember-addons/ember-stripe-elements/components/stripe-elements", ["exports", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-elements"], function (_exports, _stripeElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    stripe: Ember.inject.service('stripev3'),
    tagName: '',
    layout: _stripeElements.default,
    init: function init() {
      this._super.apply(this, arguments);
      var options = Ember.get(this, 'options') || {};
      var elements = Ember.get(this, 'stripe').elements(options);
      Ember.set(this, 'elements', elements);
    }
  });
});