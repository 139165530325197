define("ember-scrollable/util/timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeout = timeout;
  function timeout(ms) {
    var promise = new Ember.RSVP.Promise(function (r) {
      Ember.run.later(r, ms);
    });
    return promise;
  }
});