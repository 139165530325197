define("ember-gestures/components/fast-action", ["exports", "ember-gestures/templates/components/fast-action"], function (_exports, _fastAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _fastAction.default,
    tagName: 'button',
    attributeBindings: ['style', 'type'],
    style: Ember.String.htmlSafe('touch-action: manipulation; -ms-touch-action: manipulation;'),
    type: 'button',
    text: '',
    action: '',
    context: '',
    click: function click() {
      this.sendAction('action', this.get('context')); // eslint-disable-line
    }
  });
});