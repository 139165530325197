define("ember-scrollable/util/measurements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHeight = getHeight;
  _exports.getWidth = getWidth;
  /**
   * Replacement for jQuery $.height()
   * Borrowed with thanks from https://github.com/nefe/You-Dont-Need-jQuery#2.2
   */
  function getHeight(el) {
    var styles = window.getComputedStyle(el);
    var height = el.offsetHeight;
    var borderTopWidth = parseFloat(styles.borderTopWidth);
    var borderBottomWidth = parseFloat(styles.borderBottomWidth);
    var paddingTop = parseFloat(styles.paddingTop);
    var paddingBottom = parseFloat(styles.paddingBottom);
    return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
  }
  /**
   * Replacement function for jQuery $.width()
   * Borrowed with thanks from https://github.com/nefe/You-Dont-Need-jQuery#2.2
   */
  function getWidth(el) {
    var styles = window.getComputedStyle(el);
    var width = el.offsetWidth;
    var borderLeftWidth = parseFloat(styles.borderLeftWidth);
    var borderRightWidth = parseFloat(styles.borderRightWidth);
    var paddingLeft = parseFloat(styles.paddingLeft);
    var paddingRight = parseFloat(styles.paddingRight);
    return width - borderLeftWidth - borderRightWidth - paddingRight - paddingLeft;
  }
});