define("ember-select/components/x-select", ["exports", "ember-select/templates/components/x-select"], function (_exports, _xSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Component.extend(Ember.Evented, {
    layout: _xSelect.default,
    classNames: ['ember-select'],
    classNameBindings: ['isOpen:es-open', 'isFocus:es-focus', 'canSearch::es-select', 'multiple:es-multiple'],
    autofocus: false,
    canSearch: true,
    disabled: false,
    dropdown: 'select-dropdown',
    freeText: false,
    isDirty: false,
    isFocus: false,
    isOpen: false,
    openOnFocus: false,
    placeholder: 'Type...',
    required: false,
    token: '',
    value: '',
    labelKey: 'label',
    valueKey: 'value',
    canClear: Ember.computed.and('enabled', 'canSearch', 'hasOptions'),
    canOpen: Ember.computed.or('hasInput', 'openOnFocus'),
    enabled: Ember.computed.not('disabled'),
    hasDropdown: Ember.computed.and('enabled', 'hasModel'),
    hasInput: Ember.computed.notEmpty('token'),
    hasModel: Ember.computed.notEmpty('model'),
    hasOptions: Ember.computed.or('hasInput', 'hasValue', 'hasValues'),
    hasValue: Ember.computed.notEmpty('value'),
    hasValues: Ember.computed.notEmpty('values'),
    multiple: Ember.computed.bool('values'),
    shouldFilter: Ember.computed.or('isDirty', 'multiple', 'hasChanged'),
    get input() {
      return document.querySelector("#".concat(this.elementId, " input"));
    },
    hasChanged: Ember.computed('token', 'value', function () {
      var token = this.get('token');
      var option = this.get('value');
      if (Ember.isPresent(token) && Ember.isPresent(option)) {
        var _this$retrieveOption = this.retrieveOption(option),
          label = _this$retrieveOption.label;
        return token !== label;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.disabled) {
        this.set('canSearch', false);
      }
      if (!this.canSearch) {
        this.set('openOnFocus', true);
      }
      this.set('oldValue', this.get('value'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var value = this.get('value');
      if (Ember.isPresent(value)) {
        Ember.run.next(this, function () {
          return _this.setOption(value);
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // Need to open on lazy models
      if (this.isDirty) {
        this.open();
      }

      // Update input if value has changed
      var _this$retrieveOption2 = this.retrieveOption(this.value),
        label = _this$retrieveOption2.label,
        value = _this$retrieveOption2.value;
      if (this.oldLabel !== label || this.oldValue !== value) {
        this.setOption(value);
        this.set('oldLabel', label);
        this.set('oldValue', value);
      }
    },
    actions: {
      blur: function blur() {
        // IE bug: prevent closing dropdown on scrollbar click
        if (document.activeElement.classList.contains('es-options')) {
          return;
        }
        if (this.get('isDirty')) {
          // Clear unallowed input in strict single mode
          var option = this.get('freeText') ? this.get('token') : '';
          this.set('isDirty', false);
          this.setOption(option, false, !this.get('multiple'));
        }
        this.setProperties({
          isFocus: false,
          isOpen: false
        });
        if (this.onBlur) {
          this.onBlur();
        }
      },
      change: function change(query) {
        this.setProperties({
          isDirty: true,
          token: query
        });
        if (this.onChange) {
          this.onChange(query);
        }
        if (Ember.isPresent(query)) {
          this.open();
        }
      },
      clear: function clear() {
        this.set('isDirty', false);
        this.setOption('', false, !this.get('multiple'));
        if (this.onClear) {
          this.onClear();
        }
        this.send('focus');
      },
      dropdown: function dropdown() {
        var isOpen = this.toggleProperty('isOpen');
        if (isOpen) {
          this.get('input').focus();
        }
      },
      focus: function focus() {
        if (this.get('disabled')) {
          return this.send('blur');
        }
        var input = this.get('input');
        if (input) {
          input.focus();
        }
        if (input && !this.get('isFocus') && this.get('canSearch')) {
          // Select text (similar to TAB)
          input.select();
        }
        if (!this.get('isOpen')) {
          this.open();
        }
      },
      keypress: function keypress(e) {
        var isOpen = this.get('isOpen');
        switch (e.keyCode) {
          case 8:
            {
              // Backspace
              var values = this.get('values');
              if (Ember.isPresent(values) && this.get('token') === '') {
                var last = this.getElement(values, Ember.get(values, 'length') - 1);
                this.onRemove(last);
                e.preventDefault();
              }
              break;
            }
          case 9: // TAB
          case 13:
            // Enter
            if (isOpen) {
              this.trigger('keyPress', e);
            } else if (this.get('freeText')) {
              this.send('select', this.get('token'), false);
            }
            break;
          case 27:
            // ESC
            if (this.get('canSearch') && this.get('hasInput')) {
              this.send('clear');
            } else {
              this.set('isOpen', false);
            }
            break;
          case 38: // Up Arrow
          case 40:
            // Down Arrow
            if (isOpen) {
              this.trigger('keyPress', e);
            } else {
              this.set('isOpen', true);
            }
            e.preventDefault();
            break;
        }
      },
      remove: function remove(selection) {
        this.onRemove(selection);
        this.send('focus');
      },
      select: function select(option, selected) {
        var isNew = !selected && this.get('freeText') && this.get('isDirty');
        var allowNew = Ember.isPresent(this.onCreate);
        var valid = Ember.isPresent(option);

        /* Notify when option is either
         *  - selected
         *  - new, empty and cannot be created */
        var notify = selected || isNew && !allowNew;
        if (allowNew && valid && isNew) {
          this.onCreate(option);
        }
        this.set('isDirty', false);
        this.setOption(option, selected, notify);

        // Blur on selection when single
        if (!this.get('multiple')) {
          this.get('input').blur();
        }
      }
    },
    // Handle plain arrays and Ember Data relationships
    getElement: function getElement(model, position) {
      return model[position] || model.objectAt(position);
    },
    open: function open() {
      this.setProperties({
        isOpen: this.get('hasDropdown') && this.get('canOpen'),
        isFocus: true
      });
    },
    /* Retrieve `option`, `value` and `label` given a selection
     * which can be either an option (object) or a value */
    retrieveOption: function retrieveOption(option) {
      var model = this.get('model');
      var label = option;
      var value = option;
      if (Ember.isBlank(option)) {
        label = '';
      } else if (_typeof(option) === 'object') {
        label = Ember.get(option, this.get('labelKey'));
        value = Ember.get(option, this.get('valueKey'));
      } else if (Ember.isPresent(model) && _typeof(this.getElement(model, 0)) === 'object') {
        var id = this.get('valueKey');
        option = model.filter(function (x) {
          return Ember.get(x, id) === option;
        }).shift();
        if (option) {
          label = Ember.get(option, this.get('labelKey'));
        }
      }
      return {
        option: option,
        value: value,
        label: label
      };
    },
    setOption: function setOption(selection, selected, notify) {
      var _this$retrieveOption3 = this.retrieveOption(selection),
        option = _this$retrieveOption3.option,
        value = _this$retrieveOption3.value,
        _this$retrieveOption4 = _this$retrieveOption3.label,
        label = _this$retrieveOption4 === void 0 ? '' : _this$retrieveOption4;
      if (this.get('multiple')) {
        label = '';
      }
      if (!selected && notify && this.get('required')) {
        return this.setOption(this.get('value'));
      }
      if (this.get('isDirty')) {
        this.set('isDirty', false);
      } else {
        this.set('token', '');

        // Ensure the component hasn't been destroyed before updating
        var input = this.get('input');
        if (input) {
          input.value = label;
        }
      }
      if (notify && this.onSelect) {
        this.onSelect(value, option, selected);
        this.set('isOpen', false);
      }
    }
  });
});