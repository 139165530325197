define("ember-scroll-to/services/scroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DURATION = 750;
  var EASING = 'swing';
  var OFFSET = 0;
  var RSVP = Ember.RSVP;
  var _default = _exports.default = Ember.Service.extend({
    // ----- Static properties -----
    duration: DURATION,
    easing: EASING,
    offset: OFFSET,
    // ----- Computed properties -----
    scrollable: Ember.computed(function () {
      return Ember.$('html, body');
    }),
    // ----- Methods -----
    getJQueryElement: function getJQueryElement(target) {
      var jQueryElement = Ember.$(target);
      if (!jQueryElement) {
        Ember.Logger.warn("element couldn't be found:", target);
        return;
      }
      return jQueryElement;
    },
    getScrollableTop: function getScrollableTop() {
      // because the target elements top is calculated relative to the document,
      // and if the scrollable container is not the document,
      // we need to normalize the target elements top based on the top and current scrolled position of the scrollable
      if (this.get('scrollable').offset().top) {
        return this.get('scrollable').scrollTop() - this.get('scrollable').offset().top;
      } else {
        return 0;
      }
    },
    getVerticalCoord: function getVerticalCoord(target) {
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var jQueryElement = this.getJQueryElement(target);
      return this.getScrollableTop() + jQueryElement.offset().top + offset;
    },
    scrollVertical: function scrollVertical(target) {
      var _this = this;
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new RSVP.Promise(function (resolve, reject) {
        _this.get('scrollable').animate({
          scrollTop: _this.getVerticalCoord(target, opts.offset)
        }, opts.duration || _this.get('duration'), opts.easing || _this.get('easing'), opts.complete).promise().then(resolve, reject);
      });
    }
  });
});