define("ember-select/components/select-dropdown-option", ["exports", "ember-select/templates/components/select-dropdown-option"], function (_exports, _selectDropdownOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _selectDropdownOption.default,
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('handleMouseEnter', Ember.run.bind(this, function () {
        return _this.hover(_this.model);
      }));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },
    click: function click() {
      this.select(this.model);
    }
  });
});