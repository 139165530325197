define("ember-gestures/templates/components/slide-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cQIiE5c/",
    "block": "[[[10,0],[14,0,\"slideToggleButton\"],[12],[13]],[],false,[]]",
    "moduleName": "ember-gestures/templates/components/slide-toggle.hbs",
    "isStrictMode": false
  });
});