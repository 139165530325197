define("ember-moment/helpers/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    moment: Ember.inject.service(),
    disableInterval: false,
    globalAllowEmpty: Ember.computed('moment.__config__.allowEmpty', function () {
      return this.get('moment.__config__.allowEmpty');
    }),
    supportsGlobalAllowEmpty: true,
    localeOrTimeZoneChanged: Ember.observer('moment.locale', 'moment.timeZone', function () {
      this.recompute();
    }),
    compute: function compute(value, _ref) {
      var _this = this;
      var interval = _ref.interval;
      if (Ember.get(this, 'disableInterval')) {
        return;
      }
      this.clearTimer();
      if (interval) {
        /*
         * NOTE: intentionally a setTimeout so tests do not block on it
         * as the run loop queue is never clear so tests will stay locked waiting
         * for queue to clear.
         */
        this.intervalTimer = setTimeout(function () {
          Ember.run(function () {
            return _this.recompute();
          });
        }, parseInt(interval, 10));
      }
    },
    morphMoment: function morphMoment(time, _ref2) {
      var locale = _ref2.locale,
        timeZone = _ref2.timeZone;
      var momentService = Ember.get(this, 'moment');
      locale = locale || Ember.get(momentService, 'locale');
      timeZone = timeZone || Ember.get(momentService, 'timeZone');
      if (locale && time.locale) {
        time = time.locale(locale);
      }
      if (timeZone && time.tz) {
        time = time.tz(timeZone);
      }
      return time;
    },
    clearTimer: function clearTimer() {
      clearTimeout(this.intervalTimer);
    },
    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});