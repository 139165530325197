define("@adopted-ember-addons/ember-stripe-elements/services/stripev3", ["exports", "@adopted-ember-addons/ember-stripe-elements/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  // As listed at https://stripe.com/docs/stripe-js/reference#the-stripe-object
  var STRIPE_FUNCTIONS = ['elements', 'confirmCardPayment', 'createToken', 'createSource', 'createPaymentMethod', 'retrieveSource', 'paymentRequest', 'redirectToCheckout', 'retrievePaymentIntent', 'handleCardPayment', 'handleCardAction', 'confirmPaymentIntent', 'handleCardSetup', 'confirmCardSetup', 'retrieveSetupIntent', 'confirmSetupIntent'];
  var _default = _exports.default = Ember.Service.extend({
    config: null,
    didConfigure: false,
    didLoad: false,
    lazyLoad: Ember.computed.readOnly('config.lazyLoad'),
    mock: Ember.computed.readOnly('config.mock'),
    publishableKey: null,
    stripeOptions: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('publishableKey', this.get('config.publishableKey'));
      this.set('stripeOptions', this.get('config.stripeOptions'));
      var lazyLoad = this.get('lazyLoad');
      this.set('_stripeElements', Ember.A());
      if (!lazyLoad) {
        this.configure();
      }
    },
    load: function load() {
      var _this = this;
      var publishableKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var stripeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      if (publishableKey) {
        this.set('publishableKey', publishableKey);
      }
      if (stripeOptions) {
        this.set('stripeOptions', stripeOptions);
      }
      var lazyLoad = this.get('lazyLoad');
      var mock = this.get('mock');
      var shouldLoad = lazyLoad && !mock;
      var doLoad = shouldLoad ? (0, _loadScript.default)("https://js.stripe.com/v3/") : Ember.RSVP.resolve();
      return doLoad.then(function () {
        _this.configure();
        _this.set('didLoad', true);
      });
    },
    configure: function configure() {
      var didConfigure = this.get('didConfigure');
      if (!didConfigure) {
        var publishableKey = this.get('publishableKey');
        var stripeOptions = this.get('stripeOptions');
        if (!publishableKey) {
          throw new Ember.Error("stripev3: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
        }
        var stripe = new Stripe(publishableKey, stripeOptions);
        var functions = Ember.getProperties(stripe, STRIPE_FUNCTIONS);
        Ember.setProperties(this, functions);
        this.set('didConfigure', true);
      }
    },
    addStripeElement: function addStripeElement(stripeElement) {
      this._stripeElements.pushObject(stripeElement);
    },
    removeStripeElement: function removeStripeElement(stripeElement) {
      this._stripeElements.removeObject(stripeElement);
    },
    getActiveElements: function getActiveElements() {
      return _toConsumableArray(this._stripeElements);
    }
  });
});