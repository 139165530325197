define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V0IEmsJV",
    "block": "[[[18,1,[[28,[37,1],null,[[\"head\",\"body\",\"foot\"],[[50,\"lt-head\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[33,3],[33,4],[33,5],[33,6],[33,7],[33,8]]]],[50,\"lt-body\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[33,3],[33,4],[33,5],[33,6],[33,7],[33,8]]]],[50,\"lt-foot\",0,null,[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[33,3],[33,4],[33,5],[33,6],[33,7],[33,8]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"elementId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"]]",
    "moduleName": "ember-light-table/templates/components/light-table.hbs",
    "isStrictMode": false
  });
});