define("ember-moment/helpers/moment-subtract", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _exports.default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params, _ref) {
      var _this$morphMoment;
      var precision = _ref.precision,
        locale = _ref.locale,
        timeZone = _ref.timeZone;
      this._super.apply(this, arguments);
      var moment = Ember.get(this, 'moment');
      var length = params.length;
      var args = [];
      var subtractionArgs = [];
      if (length === 1) {
        subtractionArgs.push(params[0]);
      } else if (length === 2 && Ember.typeOf(params[0]) === 'number' && Ember.typeOf(params[1]) === 'string') {
        subtractionArgs.push.apply(subtractionArgs, _toConsumableArray(params));
      } else {
        args.push(params[0]);
        subtractionArgs.push.apply(subtractionArgs, _toConsumableArray(params.slice(1)));
      }
      return (_this$morphMoment = this.morphMoment(moment.moment.apply(moment, args), {
        locale: locale,
        timeZone: timeZone
      })).subtract.apply(_this$morphMoment, subtractionArgs.concat([precision]));
    })
  });
});