define("ember-scrollable/util/css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.styleify = styleify;
  function styleify(obj) {
    if (Ember.isEmpty(obj)) {
      return Ember.String.htmlSafe('');
    }
    var styles = Object.keys(obj).reduce(function (styleString, key) {
      var styleValue = obj[key];
      if (!Ember.isEmpty(styleValue)) {
        styleString += "".concat(key, ": ").concat(styleValue, "; ");
      }
      return styleString;
    }, '');
    return Ember.String.htmlSafe(styles);
  }
});