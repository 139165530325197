define("ember-gestures/components/context-element", ["exports", "ember-gestures/components/gesture-element"], function (_exports, _gestureElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _gestureElement.default.extend({
    _getParams: function _getParams(actionName) {
      var actionArguments = this._super(actionName);
      actionArguments.splice(1, 0, this.element);
      return actionArguments;
    }
  });
});