define("@adopted-ember-addons/ember-stripe-elements/components/stripe-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['ember-stripe-element'],
    autofocus: false,
    options: null,
    stripeElement: null,
    stripeError: null,
    type: null,
    // Set in components that extend from `stripe-element`

    stripev3: Ember.inject.service(),
    elements: Ember.computed({
      get: function get() {
        return Ember.get(this, 'stripev3.elements')();
      },
      set: function set(key, value) {
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Fetch user options
      var options = Ember.get(this, 'options') || {};

      // Fetch `type` set by child component
      var type = Ember.get(this, 'type');

      // `stripeElement` instead of `element` to distinguish from `this.element`
      var stripeElement = Ember.get(this, 'elements').create(type, options);

      // Mount the Stripe Element onto the mount point
      stripeElement.mount(this.element.querySelector('[role="mount-point"]'));

      // Make the element available to the component
      Ember.set(this, 'stripeElement', stripeElement);
      this.stripev3.addStripeElement(stripeElement);

      // Set the event listeners
      this.setEventListeners();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      // Fetch autofocus, set by user
      var autofocus = Ember.get(this, 'autofocus');
      var stripeElement = Ember.get(this, 'stripeElement');
      var iframe = this.element.querySelector('iframe');
      this._invokeAction('onLoad', stripeElement);
      if (autofocus && iframe) {
        iframe.onload = function () {
          stripeElement.focus();
        };
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var options = Ember.get(this, 'options') || {};
      Ember.get(this, 'stripeElement').update(options);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var stripeElement = Ember.get(this, 'stripeElement');
      this.stripev3.removeStripeElement(stripeElement);
      stripeElement.unmount();
    },
    setEventListeners: function setEventListeners() {
      var _this = this;
      var stripeElement = Ember.get(this, 'stripeElement');
      stripeElement.on('ready', function (event) {
        _this._invokeAction('onReady', stripeElement, event);
      });
      stripeElement.on('blur', function (event) {
        _this._invokeAction('onBlur', stripeElement, event);
      });
      stripeElement.on('focus', function (event) {
        _this._invokeAction('onFocus', stripeElement, event);
      });
      stripeElement.on('change', function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        var _args$ = args[0],
          complete = _args$.complete,
          stripeError = _args$.error;
        _this._invokeAction.apply(_this, ['onChange', stripeElement].concat(args));
        if (complete) {
          _this._invokeAction('onComplete', stripeElement);
        } else if (stripeError) {
          _this._invokeAction('onError', stripeError);
        }
        Ember.set(_this, 'stripeError', stripeError);
      });
    },
    _invokeAction: function _invokeAction(method) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (typeof this[method] === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }
        this[method].apply(this, args);
      }
    },
    onReady: function onReady() {},
    onBlur: function onBlur() {},
    onFocus: function onFocus() {},
    onChange: function onChange() {},
    onComplete: function onComplete() {},
    onError: function onError() {}
  });
});