define("ember-scrollable/util/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Polyfills for `Number` global.
   */
  var _default = _exports.default = {
    isNaN: Number.isNaN || window.isNaN,
    parseInt: Number.parseInt || window.parseInt
  };
});