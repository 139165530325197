define("@html-next/vertical-collection/components/vertical-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/B74tW88",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],\"id\",[[[1,[28,[35,3],[[30,1,[\"upperBound\"]]],null]],[41,[30,1,[\"isOccludedContent\"]],[[[2,[28,[37,3],[[30,1,[\"element\"]]],null]]],[]],[[[18,2,[[30,1,[\"content\"]],[30,1,[\"index\"]]]]],[]]],[1,[28,[35,3],[[30,1,[\"lowerBound\"]]],null]]],[1]],null],[1,\"\\n\"],[41,[33,6],[[[1,\"  \"],[18,3,null],[1,\"\\n\"]],[]],null]],[\"virtualComponent\",\"&default\",\"&else\"],false,[\"each\",\"-track-array\",\"virtualComponents\",\"unbound\",\"if\",\"yield\",\"shouldYieldToInverse\"]]",
    "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs",
    "isStrictMode": false
  });
});