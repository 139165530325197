define("ember-resource-metadata/private-api", ["exports", "@ember-data/store", "ember-inflector"], function (_exports, _store, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WeakMap = void 0;
  _exports.extractIdentityKey = extractIdentityKey;
  _exports.lookupIdentityKey = lookupIdentityKey;
  /*
    this module is intended to contain all uses of Ember and Ember Data
    private APIs that are needed to make this addon function
  */

  function lookupIdentityKey(store, type, id) {
    return store._internalModelForId((0, _emberInflector.singularize)((0, _store.normalizeModelName)(type)), id);
  }
  function extractIdentityKey(model) {
    return model._internalModel;
  }

  // WeakMap is in here because Ember contains a WeakMap polyfill we can
  // use, but it's not exposed by public API.
  var WeakMap;
  if (window.WeakMap) {
    _exports.WeakMap = WeakMap = window.WeakMap;
  } else {
    var metal = Ember.__loader.require('ember-metal');
    if (metal.WeakMap) {
      _exports.WeakMap = WeakMap = metal.WeakMap;
    } else {
      _exports.WeakMap = WeakMap = Ember.__loader.require('ember-metal/weak_map').default;
    }
  }
});