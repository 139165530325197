define("ember-cp-validations/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS_CLASS = _exports.IS_VALIDATIONS_CLASS = _exports.ATTRS_RESULT_COLLECTION = _exports.ATTRS_PATH = _exports.ATTRS_MODEL = void 0;
  var VALIDATIONS_CLASS = _exports.VALIDATIONS_CLASS = '__VALIDATIONS_CLASS__';
  var IS_VALIDATIONS_CLASS = _exports.IS_VALIDATIONS_CLASS = '__IS_VALIDATIONS_CLASS__';
  var ATTRS_MODEL = _exports.ATTRS_MODEL = '__ATTRS_MODEL__';
  var ATTRS_PATH = _exports.ATTRS_PATH = '__ATTRS_PATH__';
  var ATTRS_RESULT_COLLECTION = _exports.ATTRS_RESULT_COLLECTION = '__ATTRS_RESULT_COLLECTION__';
});