define("ember-select/templates/components/select-dropdown-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h29D8on3",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"options\"]]],null]],null],null,[[[41,[30,1,[\"isVisible\"]],[[[1,\"    \"],[10,0],[14,0,\"es-group\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"children\"]]],null]],null],null,[[[41,[30,2,[\"isVisible\"]],[[[1,\"        \"],[1,[28,[35,3],null,[[\"model\",\"select\",\"hover\"],[[30,2],[28,[37,4],[[30,0],\"select\"],null],[28,[37,4],[[30,0],\"hover\"],null]]]]],[1,\"\\n\"]],[]],null]],[2]],null]],[]],null]],[1]],null]],[\"group\",\"option\"],false,[\"each\",\"-track-array\",\"if\",\"select-dropdown-option\",\"action\"]]",
    "moduleName": "ember-select/templates/components/select-dropdown-group.hbs",
    "isStrictMode": false
  });
});