define("ember-maybe-in-element/components/maybe-in-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @renderInPlace}}{{yield}}{{else}}{{#in-element @destinationElement insertBefore=null}}{{yield}}{{/in-element}}{{/if}}
  */
  {
    "id": "unpEBlNX",
    "block": "[[[41,[30,1],[[[18,3,null]],[]],[[[40,[[[18,3,null]],[]],\"%cursor:0%\",[30,2],null]],[]]]],[\"@renderInPlace\",\"@destinationElement\",\"&default\"],false,[\"if\",\"yield\",\"in-element\"]]",
    "moduleName": "ember-maybe-in-element/components/maybe-in-element.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});