define("ember-scrollable/services/scrollbar-thickness", ["exports", "ember-scrollable/util/measurements"], function (_exports, _measurements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    thickness: Ember.computed(function () {
      var tempEl = document.createElement('div');
      tempEl.setAttribute('style', 'width: 50px; position: absolute; left: -100px;');
      tempEl.classList.add('scrollbar-width-tester');
      tempEl.innerHTML = "<div style=\"overflow: scroll;\"><div class=\"scrollbar-width-tester__inner\"></div></div>";
      document.body.appendChild(tempEl);
      var width = (0, _measurements.getWidth)(tempEl);
      var widthMinusScrollbars = (0, _measurements.getWidth)(tempEl.querySelector('.scrollbar-width-tester__inner'));
      return width - widthMinusScrollbars;
    })
  });
});