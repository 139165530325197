define("ember-local-storage/mixins/object", ["exports", "ember-local-storage/mixins/storage", "ember-local-storage/helpers/utils"], function (_exports, _storage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_storage.default, {
    _initialContent: {},
    _clear: function _clear() {
      Ember.set(this, 'content', {});
    },
    setUnknownProperty: _utils.saveIfChanged,
    set: _utils.saveIfChanged,
    setProperties: _utils.save
  });
});