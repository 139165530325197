define("ember-power-select/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="ember-power-select-group" aria-disabled={{if @group.disabled "true"}} role="option">
    <span class="ember-power-select-group-name">{{@group.groupName}}</span>
    {{yield}}
  </li>
  */
  {
    "id": "I8X+LRwM",
    "block": "[[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[52,[30,1,[\"disabled\"]],\"true\",null]],[14,\"role\",\"option\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"ember-power-select-group-name\"],[12],[1,[30,1,[\"groupName\"]]],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@group\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-power-select/components/power-select/power-select-group.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});