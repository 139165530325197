define("ember-uploader/components/file-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(Ember.Evented, {
    tagName: 'input',
    type: 'file',
    attributeBindings: ['name', 'disabled', 'form', 'type', 'accept', 'autofocus', 'required', 'multiple'],
    multiple: false,
    change: function change(event) {
      var input = event.target;
      if (!Ember.isEmpty(input.files)) {
        this.trigger('filesDidChange', input.files);
      }
    }
  });
});