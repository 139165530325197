define("ember-local-storage/adapters/session", ["exports", "ember-local-storage/adapters/base", "ember-local-storage/helpers/storage", "ember-local-storage/session/array"], function (_exports, _base, _storage, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    _storage: (0, _storage.getStorage)('session'),
    _getIndex: function _getIndex(type) {
      var indices = Ember.get(this, '_indices');
      if (!indices[type]) {
        var storageKey = (0, _storage._buildKey)(this, 'index-' + type);
        indices[type] = _array.default.extend({
          _storageKey: storageKey
        }).create();
      }
      return indices[type];
    }
  });
});