define("ember-gestures/components/fast-async", ["exports", "ember-gestures/templates/components/fast-async", "ember-gestures/components/async-element"], function (_exports, _fastAsync, _asyncElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _asyncElement.default.extend({
    layout: _fastAsync.default,
    tagName: 'button',
    attributeBindings: ['style', 'disabled', 'type'],
    style: Ember.String.htmlSafe('touch-action: manipulation; -ms-touch-action: manipulation;'),
    type: 'button',
    text: '',
    context: null
  });
});