define("ember-cp-validations/utils/get-with-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWithDefault;
  function getWithDefault(obj, key, defaultValue) {
    var result = Ember.get(obj, key);
    if (result === undefined) {
      result = defaultValue;
    }
    return result;
  }
});