define("ember-scroll-to/components/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // ----- Arguments -----
    href: null,
    // Required
    label: undefined,
    duration: undefined,
    easing: undefined,
    offset: undefined,
    // ----- Overridden properties -----
    tagName: 'a',
    attributeBindings: ['href'],
    // ----- Services -----
    scroller: Ember.inject.service(),
    // ----- Computed properties -----
    jQueryElement: Ember.computed('href', function () {
      var href = this.get('href');
      return this.get('scroller').getJQueryElement(href);
    }),
    // ----- Events -----
    scroll: Ember.on('click', function (evt) {
      var _this = this;
      evt.stopPropagation();
      evt.preventDefault();
      this.get('scroller').scrollVertical(this.get('jQueryElement'), {
        duration: this.get('duration'),
        offset: this.get('offset'),
        easing: this.get('easing'),
        complete: function complete() {
          return Ember.run(_this, _this.sendAction, 'afterScroll');
        }
      });
    })
  });
});