define("ember-toggle/components/x-toggle-switch/component", ["exports", "ember-toggle/components/x-toggle-switch/template", "ember-gestures/mixins/recognizers"], function (_exports, _template, _recognizers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_recognizers.default, {
    layout: _template.default,
    tagName: 'span',
    classNames: ['x-toggle-container'],
    classNameBindings: ['size', 'disabled:x-toggle-container-disabled', 'value:x-toggle-container-checked'],
    labelDisabled: false,
    recognizers: 'pan',
    effectiveForId: Ember.computed('forId', 'labelDisabled', function () {
      return this.get('labelDisabled') ? null : this.get('forId');
    }),
    themeClass: Ember.computed('theme', function () {
      var theme = this.get('theme') || 'default';
      return "x-toggle-".concat(theme);
    }),
    keyPress: function keyPress(event) {
      // spacebar: 32
      if (event.which === 32) {
        var value = this.get('value');
        this.sendToggle(!value);
        event.preventDefault();
      }
    },
    panRight: function panRight() {
      if (this.get('disabled')) {
        return;
      }
      this.get('sendToggle')(true);
      this._disableLabelUntilMouseUp();
    },
    panLeft: function panLeft() {
      if (this.get('disabled')) {
        return;
      }
      this.get('sendToggle')(false);
      this._disableLabelUntilMouseUp();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._removeListener();
    },
    /*
      When you pan with a mouse and release the mouse button over the <label>
      element, a click event happens and returns the toggle to its initial
      state. :(
       To prevent this, we need to make the label non-functional until after the
      mouse button is released.
     */
    _disableLabelUntilMouseUp: function _disableLabelUntilMouseUp() {
      var _this = this;
      if (this.get('labelDisabled')) {
        return;
      }
      var _listener = function _listener() {
        Ember.run.next(function () {
          if (_this.get('isDestroying') || _this.get('isDestroyed')) {
            return;
          }
          _this._removeListener();
          _this.set('labelDisabled', false);
        });
      };
      this.setProperties({
        labelDisabled: true,
        _listener: _listener
      });
      document.addEventListener('mouseup', _listener);
    },
    _removeListener: function _removeListener() {
      var _listener = this.get('_listener');
      if (_listener) {
        document.removeEventListener('mouseup', _listener);
        this.set('_listener', null);
      }
    }
  });
});