define("ember-cp-validations/-private/ember-validator", ["exports", "ember-cp-validations/validators/base", "ember-validators"], function (_exports, _base, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _base.default.extend({
    validate: function validate() {
      var result = _emberValidators.validate.apply(void 0, [this.get('_evType')].concat(Array.prototype.slice.call(arguments)));
      if (result && _typeof(result) === 'object') {
        return result.message ? result.message : this.createErrorMessage(result.type, result.value, result.context);
      }
      return result;
    }
  });
});