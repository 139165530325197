define("ember-gestures/recognizers/single-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    include: [],
    exclude: ['double-tap'],
    eventName: 'tap',
    options: {
      taps: 1
    },
    recognizer: 'tap'
  };
});