define("ember-cli-string-helpers/utils/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uppercase;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function uppercase() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    if (typeof string !== 'string') {
      throw new TypeError("Expected a string, got a ".concat(_typeof(string)));
    }
    return string.toUpperCase();
  }
});