define("ember-scrollable/templates/components/ember-scrollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SXh5Zotw",
    "block": "[[[10,0],[15,0,[29,[\"drag-handle \",[52,[33,1],\"visible\",null]]]],[15,\"onmousedown\",[28,[37,2],[[30,0],\"startDrag\"],null]],[15,5,[36,3]],[12],[13],[1,\"\\n\"]],[],false,[\"if\",\"showHandle\",\"action\",\"handleStyles\"]]",
    "moduleName": "ember-scrollable/templates/components/ember-scrollbar.hbs",
    "isStrictMode": false
  });
});