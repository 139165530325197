define("ember-inflector/lib/system", ["exports", "ember-inflector/lib/system/inflector", "ember-inflector/lib/system/string", "ember-inflector/lib/system/inflections"], function (_exports, _inflector, _string, _inflections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Inflector", {
    enumerable: true,
    get: function get() {
      return _inflector.default;
    }
  });
  Object.defineProperty(_exports, "defaultRules", {
    enumerable: true,
    get: function get() {
      return _inflections.default;
    }
  });
  Object.defineProperty(_exports, "pluralize", {
    enumerable: true,
    get: function get() {
      return _string.pluralize;
    }
  });
  Object.defineProperty(_exports, "singularize", {
    enumerable: true,
    get: function get() {
      return _string.singularize;
    }
  });
  _inflector.default.inflector = new _inflector.default(_inflections.default);
});