define("ember-component-inbound-actions/inbound-actions", ["exports", "ember-component-inbound-actions/action-proxy"], function (_exports, _actionProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var scheduleInAfterRender = /^1\.13|^[2-9]/.test(Ember.VERSION);
  var _default = _exports.default = Ember.Mixin.create({
    _inbound_actions_setup: Ember.on('init', function () {
      var _this = this;
      this._inbound_actions_maybeScheduleInAfterRender(function () {
        var proxy = _actionProxy.default.create({
          target: _this
        });
        _this.set('actionReceiver', proxy);
      });
    }),
    _inbound_actions_maybeScheduleInAfterRender: function _inbound_actions_maybeScheduleInAfterRender(fn) {
      if (scheduleInAfterRender) {
        Ember.run.schedule('afterRender', this, fn);
      } else {
        fn();
      }
    },
    onDestroy: Ember.on('willDestroyElement', function () {
      this.set('actionReceiver', null);
    })
  });
});