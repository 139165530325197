define("ember-gestures/utils/is-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //ensure singleton
  function MobileDetection() {
    var IS_MOBILE;

    // Set our belief about whether the devise is mobile by inspecting ontouchstart
    this.detect = function () {
      IS_MOBILE = !!("ontouchstart" in window);
    };

    // Return the current belief about whether the browser is mobile.
    this.is = function () {
      return IS_MOBILE;
    };

    // This will generally only be done in tests.
    this.fake = function (value) {
      IS_MOBILE = value;
    };

    // Set the initial value of IS_MOBILE so that calls to isMobile will have
    // the correct value.
    this.detect();
  }
  var _default = _exports.default = new MobileDetection();
});